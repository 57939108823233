import React, { Component } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";

import Label from "components/Elements/Label";
import Button from "components/Elements/Button";

import AuthUserContext from "../components/Session/AuthUserContext";
import withAuthorization from "../components/Session/withAuthorization";

import { fetchTutorialById, pushSubmittedTutorialToWeb } from "api/db";
import { auth } from "api/firebase";

import { SmallPill } from "components/Post/styles";

import { Row, Col } from "react-grid-system";

import { Project, ArticleWrapper } from "components/Post/styles";
import HeaderLivePreview from "components/Post/HeaderLivePreview";

import hljs from "highlight.js";
import Markdown from "react-remarkable-with-plugins";
import Spinner from "react-spinkit";

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const highlight = (str, lang) => {
  if (lang && hljs.getLanguage(lang)) {
    try {
      return hljs.highlight(lang, str).value;
    } catch (err) {
      console.error(err);
    }
  }

  try {
    return hljs.highlightAuto(str).value;
  } catch (err) {
    console.error(err);
  }

  return "";
};

const Container = styled.div`
  font-size: 20px;
  padding: 32px;
`;

const InputContainer = styled.div`
  text-align: center;
`;

const PreviewContainer = styled.div`
  max-width: 50em;
  margin: 0 auto;
  height: 600px;
  overflow: auto;
`;

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

class EditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      author: "",
      author_uid: "",
      author_username: "",
      title: "",
      description: "",
      language: "",
      difficulty: "",
      category: "",
      img: "",
      demoURL: "",
      sourceURL: "",
      content: ".",
      date: "",
      id: "",
      projectId: -1,
      published: false,
      loading: true,
      key: "",
      projectUpdated: false
    };
  }

  componentDidMount() {
    let tutorial = this.props.location.pathname.slice(6);
    this.setState({ id: tutorial });
    this.getTutorialData(tutorial);
  }

  getTutorialData = tutorial => {
    fetchTutorialById(tutorial).once(
      "value",
      function(snapshot) {
        let data = snapshot.val();
        this.setState({
          author: data.author,
          author_uid: data.author_uid,
          author_username: data.author_username,
          title: data.title,
          description: data.description,
          language: data.language,
          difficulty: data.difficulty,
          category: data.category,
          img: data.img,
          demoURL: data.demoURL,
          sourceURL: data.sourceURL,
          content: data.rawBody,
          date: data.date,
          id: data.id,
          projectId: data.projectId,
          published: data.published,
          loading: false
        });
      }.bind(this)
    );
  };

  onUpdateProject = event => {
    event.preventDefault();
    pushSubmittedTutorialToWeb(
      this.state.author,
      this.state.author_uid,
      this.state.author_username,
      this.state.category,
      this.state.content,
      this.state.date,
      this.state.demoURL,
      this.state.description,
      this.state.difficulty,
      this.state.id,
      this.state.img,
      this.state.language,
      this.state.projectId,
      this.state.published,
      this.state.sourceURL,
      this.state.title
    );
    this.setState({ projectUpdated: true });
  };

  onPublishClicked = event => {
    event.preventDefault();
    this.setState({
      published: !this.state.published
    });
  };

  render() {
    if (
      auth.currentUser.uid == "qODlzGtsA0QMv5hxNqC0qy6lIRy1" ||
      auth.currentUser.uid == "7bqTTkoDvua4M3lJDpLD2Ga9Lmp2" ||
      auth.currentUser.uid == "yN5k2h91h3WFNudUWfBT3suVVep2"
    ) {
      return (
        <div>
          <Helmet title="Edit Project Details | Enlight" />
          <AuthUserContext.Consumer>
            {authUser => (
              <div>
                <Container>
                  <Label>
                    {" "}
                    <a href="/admin/dashboard"> &larr; Back</a>{" "}
                  </Label>
                  <br />
                  {this.state.loading ? (
                    <SpinnerContainer>
                      <Spinner name="ball-scale-multiple" color="#438cee" />
                    </SpinnerContainer>
                  ) : (
                    <div>
                      <Row>
                        <Col>
                          <InputContainer>
                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event => {
                                  this.setState(
                                    updateByPropertyName(
                                      "author",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="Author"
                                value={this.state.author}
                              />
                            </div>
                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event => {
                                  this.setState(
                                    updateByPropertyName(
                                      "author_uid",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="Author UID"
                                value={this.state.author_uid}
                              />
                            </div>
                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event => {
                                  this.setState(
                                    updateByPropertyName(
                                      "author_username",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="Author Username"
                                value={this.state.author_username}
                              />
                            </div>
                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event => {
                                  this.setState(
                                    updateByPropertyName(
                                      "id",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="ID"
                                value={this.state.id}
                              />
                            </div>
                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event => {
                                  this.setState(
                                    updateByPropertyName(
                                      "projectId",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="Numerical ID"
                                value={this.state.projectId}
                              />
                            </div>
                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event => {
                                  this.setState(
                                    updateByPropertyName(
                                      "date",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="Date"
                                value={this.state.date}
                              />
                            </div>
                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event => {
                                  this.setState(
                                    updateByPropertyName(
                                      "title",
                                      event.target.value
                                    )
                                  );
                                  let data = event.target.value;
                                  let id = data
                                    .replace(/\s+/g, "-")
                                    .toLowerCase();
                                  this.setState(updateByPropertyName("id", id));
                                }}
                                type="text"
                                placeholder="Project Title"
                                value={this.state.title}
                              />
                            </div>

                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event =>
                                  this.setState(
                                    updateByPropertyName(
                                      "description",
                                      event.target.value
                                    )
                                  )
                                }
                                type="text"
                                placeholder="Project Description"
                                value={this.state.description}
                              />
                            </div>

                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event =>
                                  this.setState(
                                    updateByPropertyName(
                                      "language",
                                      event.target.value
                                    )
                                  )
                                }
                                type="text"
                                placeholder="Project Language"
                                value={this.state.language}
                              />
                            </div>

                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event =>
                                  this.setState(
                                    updateByPropertyName(
                                      "category",
                                      event.target.value
                                    )
                                  )
                                }
                                type="text"
                                placeholder="Project Category: Web, ML, etc"
                                value={this.state.category}
                              />
                            </div>

                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event =>
                                  this.setState(
                                    updateByPropertyName(
                                      "difficulty",
                                      event.target.value
                                    )
                                  )
                                }
                                type="text"
                                placeholder="Project Difficulty: Beginner, Intermediate, Hard"
                                value={this.state.difficulty}
                              />
                            </div>

                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event =>
                                  this.setState(
                                    updateByPropertyName(
                                      "img",
                                      event.target.value
                                    )
                                  )
                                }
                                type="text"
                                placeholder="Project Image Link"
                                value={this.state.img}
                              />
                            </div>

                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event =>
                                  this.setState(
                                    updateByPropertyName(
                                      "demoURL",
                                      event.target.value
                                    )
                                  )
                                }
                                type="text"
                                placeholder="Project Demo Link"
                                value={this.state.demoURL}
                              />
                            </div>

                            <div className="input-submit">
                              <input
                                className="large input-center"
                                onChange={event =>
                                  this.setState(
                                    updateByPropertyName(
                                      "sourceURL",
                                      event.target.value
                                    )
                                  )
                                }
                                type="text"
                                placeholder="GitHub Repo Link"
                                value={this.state.sourceURL}
                              />
                            </div>
                            <div onClick={this.onPublishClicked}>
                              {this.state.published ? (
                                <SmallPill c="#7ef29d">Published</SmallPill>
                              ) : (
                                <SmallPill c="#f7b3b7">Not Published</SmallPill>
                              )}
                            </div>
                          </InputContainer>
                        </Col>
                        <Col>
                          <HeaderLivePreview
                            title={this.state.title}
                            description={this.state.description}
                            writer={this.state.author}
                            writerId={this.state.author_username}
                            category={this.state.category}
                            language={this.state.language}
                            difficulty={this.state.difficulty}
                            date={this.state.date}
                            id={this.state.id}
                            img={this.state.img}
                            source={this.state.sourceURL}
                            demourl={this.state.demoURL}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <textarea
                            className="submission"
                            onChange={event => {
                              this.setState(
                                updateByPropertyName(
                                  "content",
                                  event.target.value
                                )
                              );
                            }}
                            type="text"
                          >
                            {this.state.content}
                          </textarea>
                        </Col>
                        <Col sm={6}>
                          <PreviewContainer>
                            <ArticleWrapper>
                              <Project>
                                <Markdown options={{ highlight, html: true }}>
                                  {this.state.content}
                                </Markdown>{" "}
                              </Project>
                            </ArticleWrapper>
                          </PreviewContainer>
                        </Col>
                      </Row>

                      <br />
                      {this.state.projectUpdated ? (
                        <Button>Done &#10004;</Button>
                      ) : (
                        <Button onClick={this.onUpdateProject}>
                          Publish Changes
                        </Button>
                      )}
                    </div>
                  )}
                </Container>
              </div>
            )}
          </AuthUserContext.Consumer>
        </div>
      );
    } else {
      return "404";
    }
  }
}
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(EditPage);
